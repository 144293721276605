@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer-seminario {
  background: #fff;
  padding: 0;
  color: #444444;
  font-size: 14px;
  background: #ebf7fc;
}

#footer-seminario .footer-newsletter {
  padding: 50px 0;
  background: #1b1b1b;
  font-size: 15px;
}

#footer-seminario .footer-newsletter h4 {
  font-size: 24px;
  margin: 0 0 20px 0;
  padding: 0;
  line-height: 1;
  font-weight: 600;
  color: #0f394c;
}

#footer-seminario p {
  text-align: unset;
}

#footer-seminario a {
  text-decoration: unset;
}

.logo-2{
  margin:5px;
}

.menuColumn.menuLeft ul{
  list-style: none!important;
  margin: 0;
  padding: 0;
  text-align: left!important;
}

.menuColumn.menuLeft ul li a{
  display: block;
  color: #ffff;
  margin-bottom: 5px;
  text-decoration: unset;
}

html{height:100%}body{background:#fafafa !important;height:100%;margin:0;font-family:-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif}@media print{.no-print{display:none}}

